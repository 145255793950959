import { useState, useEffect } from 'react';
import * as React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { POSTCARDS } from "../src/data";
import HomePage from './components/HomePage';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/turtles" element={<GalleryPage initialTag="turtle" />} />
        <Route path="/loupaper" element={<GalleryPage initialTag="loupaper" />} />
        <Route path="/papersisters" element={<GalleryPage initialTag="papersisters" />} />
        <Route path="/harrypotter" element={<GalleryPage initialTag="harrypotter" />} />
        <Route path="/pokemon" element={<GalleryPage initialTag="pokemon" />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

function GalleryPage({ initialTag = "" }) {
  const [selectedCards, setSelectedCards] = useState(
    initialTag ? POSTCARDS.filter(postcard => postcard.order.hasOwnProperty(initialTag)) : POSTCARDS
  );

  useEffect(() => {
    if (initialTag) {
      filterPostcards(initialTag);
    }
  }, [initialTag]);

  function filterPostcards(tag) {
    let filteredPostcards;
    if (tag === "") {
      filteredPostcards = POSTCARDS;
    } else {
      filteredPostcards = POSTCARDS.filter(postcard => postcard.order.hasOwnProperty(tag));
    }
    // Sort the postcards by the custom order for the given tag
    filteredPostcards.sort((a, b) => (a.order[tag] || 0) - (b.order[tag] || 0));
    setSelectedCards(filteredPostcards);
  }

  return (
    <div id="app-container">
      <div id="header">
        <div id="title">
          <h1>Welcome to my postcard gallery</h1>
        </div>
        <div id="tag-container" className="form">
          <button class="gradient-border-button" onClick={() => filterPostcards("turtle")}>Turtles</button>
          <button class="gradient-border-button" onClick={() => filterPostcards("loupaper")}> LouPaper</button>
          <button class="gradient-border-button" onClick={() => filterPostcards("harrypotter")}>Harry Potter</button>
          <button class="gradient-border-button" onClick={() => filterPostcards("pokemon")}>Pokémon</button>
          <button class="gradient-border-button" onClick={() => filterPostcards("papersisters")}>Papersisters</button>
        </div>
      </div>
      <Gallery postcardSelection={selectedCards} />
    </div>
  );
}

function Gallery({ postcardSelection }) {
  const [index, setIndex] = React.useState(-1);

  return (
    <div className="gallery">
      <div className="postcard-display">
        {postcardSelection.map((postcard, i) => (
          <PostcardPreview
            key={postcard.id}
            url={postcard.photos[0]}
            onClickHandler={() => setIndex(i)}
          />
        ))}
      </div>
      <Lightbox
        index={index}
        open={index >= 0}
        close={() => setIndex(-1)}
        slides={postcardSelection.map(postcard => ({ src: postcard.photos[0] }))}
      />
    </div>
  );
}

function PostcardPreview({ url, onClickHandler }) {
  return (
    <div className="postcard">
      <img src={url} alt="Postcard" onClick={onClickHandler} />
    </div>
  );
}
